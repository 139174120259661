import React from 'react';
import { Radio, Typography } from '@mui/material';

import { TAlgoliaRadioProps } from '../../types';
import { SFormControlLabel } from '../styles';

export function AlgoliaRadio({ item, onChange, noBorder = false }: TAlgoliaRadioProps): JSX.Element {
  const { label, value, isRefined } = item;
  return (
    <SFormControlLabel
      noBorder={noBorder}
      label={
        <>
          <Typography variant='body1' color='textSecondary'>
            <span>{label}</span>
          </Typography>
        </>
      }
      control={<Radio value={value} checked={isRefined} onChange={onChange} />}
    />
  );
}
