import React from 'react';

import IndividualInfo from '../../../IndividualInfo';
import { ResidentInfoDataTypes } from '../../../../../../types/types';
import { BigTitle, Flex } from '../../../../style';

const ResidentInfo = ({ applicationType, rentalName, rentalUnitNumber, tenantName }: ResidentInfoDataTypes) => {
  return (
    <>
      <BigTitle component='h2' variant='h2'>
        Resident info
      </BigTitle>
      <Flex>
        <IndividualInfo label='Property name' value={rentalName} />
        <IndividualInfo label='Type of application' value={applicationType} width='45%' />
      </Flex>
      <Flex>
        <IndividualInfo label='Unit number' value={rentalUnitNumber} />
        <IndividualInfo label='Tenant Name' value={tenantName} width='45%' />
      </Flex>
    </>
  );
};

export default ResidentInfo;
