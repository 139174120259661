import { authSplitApi } from 'redux/helpers/slice.helpers';
import { Auth } from 'aws-amplify';

export const AuthAmplify = authSplitApi('amplify').injectEndpoints({
  endpoints: (build) => ({
    getCurrentAuthenticatedUser: build.mutation<any, void>({
      // @ts-ignore
      async queryFn() {
        try {
          const amplifyUser = await Auth.currentAuthenticatedUser();

          return { data: !!amplifyUser?.username };
        } catch (e) {
          const user = localStorage.getItem('user');

          if (user) {
            localStorage.clear();
            window.location.reload();
          }
          return { error: e };
        }
      },
    }),
  }),
  overrideExisting: false,
});

export const { useGetCurrentAuthenticatedUserMutation } = AuthAmplify;
