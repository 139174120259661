import React from 'react';
import Typography from '@mui/material/Typography';
import { IndividualInfoBlock, InfoTitle } from '../../../components/Base/style';
import { IndividualInfoProps } from '../../../types/types';

const IndividualInfo = ({ label, value, ...props }: IndividualInfoProps) => {
  return (
    <IndividualInfoBlock {...props}>
      <InfoTitle>{label}</InfoTitle>
      <Typography component='h5' variant='h5'>
        {value || 'N/A'}
      </Typography>
    </IndividualInfoBlock>
  );
};

export default IndividualInfo;
