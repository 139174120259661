import styled from 'styled-components';

import Dialog from '@mui/material/Dialog';

export const ModalDialog = styled(Dialog)`
  &.report-animal-complaint .MuiPaper-root::-webkit-scrollbar-thumb {
    background: unset;
  }
`;

export const Close = styled.div<{ onClick?: () => void }>`
  margin-top: -10px;
`;

export const Header = styled.div`
  display: flex;
  position: fixed;
  justify-content: space-between;
  padding: 24px 20px 0 20px;
  left: 0;
  right: 0;
  background-color: white;
  z-index: 30;
`;
