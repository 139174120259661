import appSlice from 'redux/slices/app.slice';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { Button } from '@landlord-tech/opp-ui-kit';

import Modal from '../Modal';
import { FilterationFooter, SFilterWrapper } from './styles';

type FilterProps = {
  children: JSX.Element | JSX.Element[];
  onResetFilter: () => void;
  filterTitle?: string;
};

const Filters = ({ children, onResetFilter, filterTitle = 'filters' }: FilterProps): JSX.Element => {
  const isFilterOpen = useAppSelector((state) => state.appSlice.isFilterOpen);
  const dispatch = useAppDispatch();

  const handleClose = () => {
    dispatch(appSlice.actions.toggleFilterModal());
  };

  return (
    <Modal title={filterTitle} open={isFilterOpen} handleClose={handleClose} keepMounted>
      <SFilterWrapper>{children}</SFilterWrapper>

      <FilterationFooter>
        <Button text='Apply' onClick={handleClose} />
        <Button text='Reset' onClick={onResetFilter} className='reset-btn' />
      </FilterationFooter>
    </Modal>
  );
};

export default Filters;
