import { useState } from 'react';

import { TAccordions } from './type';
import { MUIAccordion, AccordionSummary, AccordionDetails } from './style';

export default function Accordion({ list }: TAccordions): JSX.Element {
  const [expanded, setExpanded] = useState('');

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : '');
  };

  return (
    <>
      {list.map(({ header: Header, details }, index) => {
        return (
          <MUIAccordion
            key={`acc-${index}`}
            expanded={expanded === `panel${index}`}
            onChange={handleChange(`panel${index}`)}
          >
            <AccordionSummary id={`acc-header-id-${index}`}>
              <Header isExpanded={expanded === `panel${index}`} />
            </AccordionSummary>
            <AccordionDetails>{details}</AccordionDetails>
          </MUIAccordion>
        );
      })}
    </>
  );
}
