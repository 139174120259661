import React, { useEffect } from 'react';

import { useParams } from 'react-router-dom';
import MainLayout from '../../components/Base/MainLayout';
import AnimalComplaintBlock from './components/AnimalComplaintBlock';
import AnimalComplaintInformation from './components/AnimalComplaintInformation';
import { useAppSelector } from '../../redux/store';
import { useGetComplaintQuery } from '../../redux/apiSlice/complaint.slices';

const AnimalComplaint = () => {
  const { id } = useParams<{ id: string }>();
  const { user: profile } = useAppSelector((state) => ({
    user: state.authSlice.user,
  }));

  useEffect(() => {
    const complaintAnimalImages = document.querySelectorAll<HTMLElement>('.complaint-animal-image');
    complaintAnimalImages.forEach((item, index) => {
      const imageHeight = complaintAnimalImages[0].clientWidth;
      complaintAnimalImages[index].style.height = `${imageHeight}px`;
    });
  }, []);

  const { data } = useGetComplaintQuery(
    {
      complaintId: id,
      userId: profile?.id || '',
    },
    { skip: !profile?.id }
  );

  return (
    <MainLayout padding='0 20px 8px 20px'>
      {data && (
        <>
          <AnimalComplaintBlock data={data?.complaint} />
          <AnimalComplaintInformation data={data?.complaintInfo} />
        </>
      )}
    </MainLayout>
  );
};

export default AnimalComplaint;
