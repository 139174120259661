import { authSplitApi } from '../helpers/slice.helpers';

const AUTH = 'auth';
const FORGOT_PASSWORD = 'forgotPassword';

export const AuthApi = authSplitApi('barlus').injectEndpoints({
  endpoints: (build) => ({
    forgotPassword: build.mutation<void, { phone: string }>({
      query(body) {
        return {
          url: `${AUTH}/${FORGOT_PASSWORD}`,
          method: 'POST',
          body,
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const { useForgotPasswordMutation } = AuthApi;
