import { useHistory } from 'react-router-dom';
import { Divider } from '@mui/material';
import { LogOutField, LogOutPart, LogOutText } from '../../styles';
import { LogOutIcon } from '../../../../components/Base/Icons/Icons';
import { UseAmplifySignOut } from '../../../../Hooks/maintainerAWS';
import { useAppDispatch } from '../../../../contexts/store';
import { useAppDispatch as useAppDispatchRedux } from '../../../../redux/store';
import authSlice from '../../../../redux/slices/auth.slice';

type LogOutProps = {
  handleLoading: (val: boolean) => void;
};

const LogOut = ({ handleLoading }: LogOutProps) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const dispatchRedux = useAppDispatchRedux();
  const { reFetch } = UseAmplifySignOut();

  const logOut = async () => {
    handleLoading(true);
    await reFetch();

    dispatchRedux(authSlice.actions.removeUser());
    dispatchRedux({ type: 'USER_LOGOUT' });
    history.push('/');
    dispatch({ type: 'RESET_STATE' });
    handleLoading(false);
  };

  return (
    <LogOutPart>
      <Divider />
      <LogOutField onClick={logOut}>
        <LogOutIcon />
        <LogOutText>Log Out</LogOutText>
      </LogOutField>
    </LogOutPart>
  );
};

export default LogOut;
