import styled from '@emotion/styled';
import { theme } from 'components/theme';
import { Chip } from '@mui/material';

const Theme = theme as { [key: string]: any };

export const SChip = styled(Chip)`
  margin: 4px 4px 0 0;
  font-family: cabin;
  font-weight: bold;
  color: ${Theme.textSecondary};
  border: 1px solid ${Theme.yellow};
  background: transparent;
  & .MuiChip-deleteIconOutlinedColorDefault,
  .MuiChip-deleteIconOutlinedColorDefault:hover {
    color: ${Theme.yellow};
  }
`;
export const SCountChip = styled(Chip)`
  height: auto;
  padding: 0 4px;
  margin: 0 4px;
  font-family: cabin;
  font-size: 12px;
  font-weight: bold;
  color: ${Theme.textSecondary};
  border: 1px solid #b6b7d5;
`;
