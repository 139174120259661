import React from 'react';

class ErrorBoundary extends React.Component {
  componentDidCatch(error: Error) {
    if (error?.name?.includes('ChunkLoadError')) {
      window.location.reload();
    }
  }

  render() {
    return this.props.children;
  }
}

export default ErrorBoundary;
