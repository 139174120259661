import { useEffect, useMemo, useState } from 'react';
import { Typography } from '@mui/material';

import CurrentRefinementsList from 'components/Base/Algolia/components/RefinementList/CurrentRefinementsList';
import { TAccordionProps } from 'components/Base/AccordionList/type';

import { filterItems } from './filter';
import { SChip, SCountChip } from '../style';

export const useGetRefinementList = () => {
  const [list, setList] = useState<TAccordionProps[]>([]);
  const refinementList = useMemo(
    () =>
      filterItems.map(({ header, details, attribute }) => {
        return {
          header: ({ isExpanded }) => (
            <>
              <Typography>
                {header}
                {!details && <SCountChip label='0' size='small' />}
              </Typography>
              {!isExpanded && (
                <CurrentRefinementsList
                  attribute={attribute}
                  component={({ refine, list: currentList }) => (
                    <div>
                      {currentList.map((item) => (
                        <SChip key={item.label} label={item.label} onDelete={() => refine(item.value)} />
                      ))}
                    </div>
                  )}
                />
              )}
            </>
          ),
          details,
        };
      }) as TAccordionProps[],
    []
  );

  useEffect(() => {
    setList(refinementList);
  }, []);

  return { list };
};
