import { TUnits, TUnitsFromDb } from '../types/types/Unit';
import { simplePropertyConverter } from './rental.converter';

// todo fix any type
export const simpleUnitConverter = (data: TUnitsFromDb): TUnits => ({
  id: `${data.id}`,
  number: data.number,
  rentalId: `${data.rentalId}`,
  rental: simplePropertyConverter(data.rental),
});

export const simpleUnitsConverter = (data: TUnitsFromDb[]): TUnits[] =>
  data.map((item) => ({
    id: `${item.id}`,
    number: item.number,
    rentalId: `${item.rentalId}`,
    rental: simplePropertyConverter(item.rental),
  }));
