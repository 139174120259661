import React from 'react';

import { Typography } from '@mui/material';
import { CloseIcon } from '../Icons/Icons';
import { ModalDialog, Close, Header } from './styles';

type ModalProps = {
  open: boolean;
  title: string;
  handleClose: () => void;
  fullScreen?: boolean;
  className?: string;
  children: JSX.Element | JSX.Element[];
  keepMounted?: boolean;
};

const Modal = ({
  open,
  handleClose,
  title,
  className,
  children,
  fullScreen = true,
  keepMounted = false,
}: ModalProps): JSX.Element => {
  return (
    <ModalDialog open={open} className={className} fullScreen={fullScreen} keepMounted={keepMounted}>
      <Header>
        <Typography component='h2' variant='h2'>
          {title}
        </Typography>
        <Close onClick={handleClose}>
          <CloseIcon />
        </Close>
      </Header>
      {children}
    </ModalDialog>
  );
};

export default Modal;
