import React from 'react';
import { Checkbox, Typography } from '@mui/material';

import { TAlgoliaCheckboxProps } from '../../types';
import { SFormControlLabel, SCountChip } from '../styles';

export function AlgoliaCheckBox({ item, onChange, noBorder = false }: TAlgoliaCheckboxProps): JSX.Element {
  return (
    <SFormControlLabel
      noBorder={noBorder}
      label={
        <>
          <Typography variant='body1' color='textSecondary'>
            <span>{item.label}</span>
            <SCountChip label={item.count} size='small' />
          </Typography>
        </>
      }
      control={<Checkbox value={item.value} checked={item.isRefined} onChange={onChange} />}
    />
  );
}
