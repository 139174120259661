import pick from 'lodash/pick';
import isEmpty from 'lodash/isEmpty';
import omitBy from 'lodash/omitBy';
import set from 'lodash/set';
import get from 'lodash/get';
import { TFilter, TSelectedFilter, FilterKeyEnum, TFilterChoiceData } from './types';

export const validateFilter = (
  filterData: TFilter,
  selectedFilters: TSelectedFilter | null
): TSelectedFilter | null => {
  if (isEmpty(selectedFilters) || !selectedFilters) {
    return null;
  }
  const res: TSelectedFilter = {};

  Object.keys(selectedFilters).forEach((key) => {
    res[key as keyof typeof FilterKeyEnum] = {};

    const { content } = filterData[key as keyof typeof FilterKeyEnum];

    const validFiltersMap = content.reduce<{ [key: string]: string[] }>((acc, item) => {
      const res = { ...acc };
      if (!item.count) {
        return res;
      }
      res[item.key] = item.choices.map((choiceItem) => choiceItem.key);
      return res;
    }, {});

    Object.keys(selectedFilters[key as keyof typeof FilterKeyEnum] || {}).forEach((selectedKey) => {
      if (!Object.keys(validFiltersMap).includes(selectedKey)) {
        return;
      }
      const validSelectedFilter = get(selectedFilters, [key, selectedKey], []).filter((item: TFilterChoiceData) =>
        validFiltersMap[selectedKey].includes(item.key)
      );
      if (!validSelectedFilter.length) {
        return;
      }
      set(res, [key, selectedKey], validSelectedFilter);
    });
  });

  return res;
};
