import get from 'lodash/get';
import { TComplaint, TComplaintData, TComplaintFromDb } from '../types/types/Complaint';

export const simpleComplaintConverter = (data: TComplaintFromDb): TComplaintData => ({
  complaint: {
    address: data.rentalAddress,
    rentalUnit: data.unitNumber,
    operatedBy: data.createdUser.type,
  },
  complaintInfo: {
    complaintType: data.animalComplaintType,
    status: data.docType,
    createdDate: data.creationDateTime,
    message: data.message,
    photos: data.photos || [],
  },
});

export const simpleComplaintsConverter = (data: TComplaintFromDb[]): TComplaint[] =>
  data.map((item) => ({
    id: item.id,
    docType: item.docType,
    animalComplaintType: item.animalComplaintType,
    creationDateTime: item.creationDateTime,
    createdUser: {
      photoUrl: get(item, 'createdUser.photoUrl', ''),
      type: get(item, 'createdUser.type', ''),
    },
  }));
