import { connectCurrentRefinements } from 'react-instantsearch-core';

import { TCurrentRefinementProps } from '../types';
import { WrappedRefinementList } from './components/WrappedRefinementList';

function CurrentRefinementsList({ attribute, component }: TCurrentRefinementProps) {
  const Component = connectCurrentRefinements((currentRefinementsProps) =>
    WrappedRefinementList({ ...currentRefinementsProps, attribute, component })
  );
  return <Component />;
}

export default CurrentRefinementsList;
