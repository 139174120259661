import { connectNumericMenu } from 'react-instantsearch-core';

import { TNumericRefinmentListItem } from '../../types';
import { WrappedNumericFilter } from './components/WrappedNumericFilter';

function NumericFilter(props: TNumericRefinmentListItem) {
  const { attribute, items } = props;
  const Component = connectNumericMenu((refinmentListProps) =>
    WrappedNumericFilter({ ...props, ...refinmentListProps })
  );
  return <Component attribute={attribute} items={items} />;
}

export default NumericFilter;
