import styled from '@emotion/styled';
import { theme } from 'components/theme';
import { FormControlLabel, Chip, FormControlLabelProps } from '@mui/material';

const Theme = theme as { [key: string]: any };

export const SFormControlLabel = styled<(props: FormControlLabelProps & { noBorder: boolean }) => JSX.Element>(
  FormControlLabel
)`
  margin-left: 0;
  margin-right: 0;
  padding: 6px 0;
  border-bottom: ${({ noBorder }) => (noBorder ? 'none' : '1px solid rgb(227, 228, 228)')};

  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  background-color: unset !important;

  &.noborder: {
    border-bottom: none;
  }
`;

export const SCountChip = styled(Chip)`
  height: auto;
  padding: 0 4px;
  margin: 0 4px;
  font-family: cabin;
  font-size: 12px;
  font-weight: bold;
  color: ${Theme.textSecondary};
  border: 1px solid #b6b7d5;
`;
