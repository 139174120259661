import { useEffect } from 'react';
import { connectCurrentRefinements, CurrentRefinementsProvided } from 'react-instantsearch-core';

import Filters from 'components/Base/Filters';
import { TRefinementListProps } from 'components/Base/Algolia/components/RefinementList/types';

import { useAppDispatch } from 'redux/store';
import appSlice from 'redux/slices/app.slice';

function WrappedRefinementList({
  transform,
  title,
  items,
  refine,
}: TRefinementListProps & CurrentRefinementsProvided): JSX.Element {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(appSlice.actions.toggleHasSelectedFilterItem({ hasSelectedFilter: !!items.length }));
  }, [items]);

  return (
    <Filters onResetFilter={() => refine(items)} filterTitle={title}>
      {transform(items)}
    </Filters>
  );
}

function RefinementList(props: TRefinementListProps) {
  const Component = connectCurrentRefinements((currentRefinementsProps) =>
    WrappedRefinementList({ ...props, ...currentRefinementsProps })
  );
  return <Component />;
}

export default RefinementList;
