import React, { useEffect, useRef, useState, ChangeEvent } from 'react';

import { TRefinmentListProvided } from '../../../types';
import { AlgoliaCheckBox } from '../../../components/CheckBox';
import { NoOptionExist } from '../../../components/NoOptionExist';
import { ControlledSearchBox } from '../../../components/ControlledSearchBox';

import { SRefinementList, SRefinementListItem, RefinementListWrapper, RefinementListNoResults } from '../../../styles';

export function WrappedMultiSelectFilter({
  items,
  isFromSearch,
  refine,
  searchForItems,
  createURL,
  searchable,
  searchablePlaceholder,
}: TRefinmentListProvided): JSX.Element {
  const [query, setQuery] = useState('');
  const previousQueryRef = useRef(query);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (previousQueryRef.current !== query) {
      previousQueryRef.current = query;
      searchForItems(query);
    }
  }, [query, searchForItems]);

  if (!items.length) {
    return <NoOptionExist />;
  }

  return (
    <RefinementListWrapper>
      {searchable && (
        <div className='ais-RefinementList-searchBox'>
          <ControlledSearchBox
            inputRef={inputRef}
            placeholder={searchablePlaceholder}
            isSearchStalled={false}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              setQuery(event.currentTarget.value);
            }}
            onReset={() => {
              setQuery('');
            }}
            onSubmit={() => {
              if (items.length > 0) {
                refine(items[0].value);
                setQuery('');
              }
            }}
            value={query}
          />
        </div>
      )}

      {searchable && isFromSearch && items.length === 0 && (
        <RefinementListNoResults variant='h5'>No results.</RefinementListNoResults>
      )}
      <SRefinementList>
        {items.map((item, index) => (
          <SRefinementListItem key={item.label}>
            <AlgoliaCheckBox
              item={item}
              noBorder={index === items.length - 1}
              onChange={() => {
                refine(item.value);
                setQuery('');
              }}
            />
          </SRefinementListItem>
        ))}
      </SRefinementList>
    </RefinementListWrapper>
  );
}
