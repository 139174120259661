import Typography from '@mui/material/Typography';
import { useHistory } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'redux/store';
import appSlice from 'redux/slices/app.slice';

import { RoutesNames } from '../../../routes/RoutesNames';

import { AnimalIcon, FilterSelectedCircleIcon, FilterIcon } from '../Icons/Icons';
import { NavItem, NavItemBox, NavItems, Filter, NavFilterBox } from './style';

const NavBar = () => {
  const hasSelectedFilterItem = useAppSelector((state) => state.appSlice.hasSelectedFilterItem);
  const dispatch = useAppDispatch();

  const history = useHistory();
  const {
    location: { pathname },
  } = history;

  return (
    <NavItems>
      {/* <NavItemBox
        selected={pathname.includes(RoutesNames.properties)}
        onClick={() => history.push(RoutesNames.properties)}
      >
        <PropertiesIcon />
        <NavItem>Properties</NavItem>
      </NavItemBox> */}
      <NavItemBox
        hidden={pathname !== RoutesNames.animals}
        selected={pathname.includes(RoutesNames.animals)}
        onClick={() => history.push(RoutesNames.animals)}
      >
        <AnimalIcon />
        <NavItem>Animals</NavItem>
      </NavItemBox>
      <NavFilterBox hidden={pathname !== RoutesNames.animals}>
        <Filter onClick={() => dispatch(appSlice.actions.toggleFilterModal())}>
          <FilterIcon />
          <Typography component='h5' variant='h5'>
            Filter
          </Typography>
          {hasSelectedFilterItem && <FilterSelectedCircleIcon />}
        </Filter>
      </NavFilterBox>
    </NavItems>
  );
};

export default NavBar;
