import { useEffect, useMemo, useState } from 'react';
import { CurrentRefinementsProvided } from 'react-instantsearch-core';
import { TCurrentRefinementProps, TSelectedFilter } from '../../types';

export function WrappedRefinementList({
  items,
  refine,
  attribute,
  component: Component,
}: CurrentRefinementsProvided & TCurrentRefinementProps): JSX.Element {
  const [list, setList] = useState<TSelectedFilter[]>([]);

  const selectedFilterList = useMemo(() => {
    return items.filter((item) => item.attribute === attribute)[0];
  }, [attribute, items]);

  useEffect(() => {
    if (!selectedFilterList) {
      return;
    }
    setList(
      selectedFilterList.items
        ? selectedFilterList.items.map(({ label, value }) => ({ label, value }))
        : [{ label: selectedFilterList.currentRefinement, value: selectedFilterList.value }]
    );
  }, [selectedFilterList]);

  return (
    <div>
      <Component refine={refine} list={list} />
    </div>
  );
}
