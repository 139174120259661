import styled from '@emotion/styled';
import { Typography } from '@mui/material';

export const SSearchLabel = styled.h5`
  font-size: 16px;
  font-weight: 400;
  margin: 0 0 24px;
`;

export const RefinementListWrapper = styled.div`
  margin-bottom: 24px;
`;

export const SRefinementList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
`;

export const SRefinementListItem = styled.li`
  display: flex;
  flex-direction: column;
`;

export const RefinementListNoResults = styled(Typography)`
  padding: 12px 0;
`;
