import { authSplitApi } from '../helpers/slice.helpers';
import { TUnits, TUnitsFromDb } from '../../types/types/Unit';
import { simpleUnitConverter, simpleUnitsConverter } from '../../convertors/unit.converter';

const URL = 'units';

export const UnitApi = authSplitApi('unit', ['Unit', 'Units']).injectEndpoints({
  endpoints: (build) => ({
    getUnit: build.query<TUnits, { unitId: string; userId: string }>({
      query({ unitId, ...params }) {
        return {
          url: `${URL}/${unitId}`,
          method: 'GET',
          params,
        };
      },
      providesTags: ['Unit'],
      transformResponse: (data: TUnitsFromDb) => {
        return simpleUnitConverter(data);
      },
    }),
    getUnits: build.query<TUnits[], { userId: string; rentalId: string; unitNumber?: string }>({
      query(params) {
        return {
          url: `${URL}`,
          method: 'GET',
          params,
        };
      },
      providesTags: ['Units'],
      transformResponse: (data: TUnitsFromDb[]) => {
        return simpleUnitsConverter(data);
      },
    }),
  }),
  overrideExisting: false,
});

export const { useGetUnitQuery, useGetUnitsQuery } = UnitApi;
