import { authSplitApi } from '../helpers/slice.helpers';
import {
  TPropertyData,
  TPropertyDataFromDb,
  TProperty,
  TPropertyFromDb,
  TPropertyNames,
  TPropertyNamesFromDb,
} from '../../types/types/Rental';
import {
  simplePropertiesConverter,
  simplePropertyConverter,
  simpleRentalNamesConverter,
} from '../../convertors/rental.converter';

const URL = 'rentals';

export const RentalApi = authSplitApi('rentals', ['Property', 'Properties', 'PropertiesName']).injectEndpoints({
  endpoints: (build) => ({
    getProperty: build.query<TProperty, { propertyId: string; userId: string }>({
      query({ propertyId, ...params }) {
        return {
          url: `${URL}/${propertyId}`,
          method: 'GET',
          params,
        };
      },
      providesTags: ['Property'],
      transformResponse: (data: TPropertyFromDb) => {
        return simplePropertyConverter(data);
      },
    }),
    getProperties: build.query<TPropertyData, { maintainerId: string; size?: number; page?: number }>({
      query(params) {
        return {
          url: `${URL}`,
          method: 'GET',
          params,
        };
      },
      providesTags: ['Properties'],
      transformResponse: (data: TPropertyDataFromDb) => {
        return {
          list: simplePropertiesConverter(data.content),
          totalElements: data.totalElements,
          totalPages: data.totalPages,
        };
      },
    }),
    getRentalNames: build.query<TPropertyNames[], void>({
      query() {
        return {
          url: `${URL}/name`,
          method: 'GET',
        };
      },
      providesTags: ['PropertiesName'],
      transformResponse: (data: TPropertyNamesFromDb[]) => {
        return simpleRentalNamesConverter(data);
      },
    }),
  }),
  overrideExisting: false,
});

export const { useGetPropertyQuery, useGetPropertiesQuery, useGetRentalNamesQuery } = RentalApi;
