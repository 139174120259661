import { TProperty, TPropertyFromDb } from '../types/types/Rental';

export const simplePropertiesConverter = (data: DataTypesComesFromBack.contentType[]): TProperty[] =>
  data.map((item) => ({
    id: item.id,
    name: item.name,
    unit: item.unitCount,
    address: item.address1,
    type: item.type,
    photoUrl: item.photoUrl,
  }));

export const simplePropertyConverter = (data: TPropertyFromDb): TProperty => ({
  id: data.id,
  name: data.name,
  unit: data.unitCount,
  address: data.address1,
  type: data.type,
  photoUrl: data.photoUrl,
  defaultPolicy: data.defaultPolicy,
});

export const simpleRentalNamesConverter = (
  data: DataTypesComesFromBack.RentalNamesRequestData[]
): { name: string; key: string }[] => {
  return data.map((item) => ({
    key: `${item.id}`,
    name: item.name,
  }));
};
