import { useEffect } from 'react';
import { CircularProgress, Typography } from '@mui/material';

import { connectStateResults } from 'react-instantsearch-dom';

import { useAppDispatch } from 'contexts/store';

import { TConnectStateResultsProps } from '../types';
import { Loading } from '../style';

export const Result = connectStateResults(
  ({ searchResults, searching, error, refreshApiKey }: TConnectStateResultsProps) => {
    const dispatch = useAppDispatch();
    const hasResults = searchResults && searchResults.nbHits !== 0;

    useEffect(() => {
      if (!error) {
        return;
      }

      /*
        Don't touch this ;)
          Ok guys, when you are reading this I'm not your teammate any more! :(
          You can judge this part that is optimized or not! but it is ;)
          let's try new thing instead of old if... else...
      */

      // switch (true) {
      //   case error.status === 400 && !!error.message.match(/validUntil/):
      //     refreshApiKey();
      //     break;
      //   case error.status === 403 && error.name === 'ApiError':
      //     dispatch({
      //       type: 'OPEN_ALERT',
      //       payload: {
      //         severity: 'error',
      //         message: 'Something went wrong',
      //       },
      //     });
      //     break;
      //   default:
      //     dispatch({
      //       type: 'OPEN_ALERT',
      //       payload: {
      //         severity: 'error',
      //         message: error.message,
      //       },
      //     });
      // }

      if (error.status === 400 && !!error.message.match(/validUntil/)) {
        refreshApiKey();
        return;
      }

      dispatch({
        type: 'OPEN_ALERT',
        payload: {
          severity: 'error',
          message: error.status === 403 && error.name === 'ApiError' ? 'Something went wrong' : error.message,
        },
      });
    }, [error]);

    return (
      <>
        <Loading hidden={!searching || !!searchResults?.query}>
          <CircularProgress />
        </Loading>
        <div hidden={searching || hasResults}>
          <Typography variant='subtitle2'>
            There is no results {searchResults?.query && `for ${searchResults.query}`}
          </Typography>
        </div>
      </>
    );
  }
);
