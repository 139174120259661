import { useMemo, memo } from 'react';
import algoliasearch from 'algoliasearch';
import { InstantSearch, Configure } from 'react-instantsearch-dom';

import { TAlgoliaProvider } from 'components/Base/Algolia/types';
import { Result } from 'components/Base/Algolia/components/Result';
import RefinementList from 'components/Base/Algolia/components/RefinementList';

const AlgoliaProvider: TAlgoliaProvider = ({
  apiKey,
  refreshApiKey,
  indexName,
  children,
  configs,
  refinementListTransform = () => <></>,
  refinementListTitle,
}) => {
  const searchClient = useMemo(() => {
    if (!apiKey) {
      return null;
    }
    return algoliasearch(process.env.REACT_APP_ALGOLIA_APP_ID!, apiKey);
  }, [apiKey]);

  if (!searchClient) return null;

  return (
    <InstantSearch searchClient={searchClient} indexName={indexName}>
      <>
        <Configure hitsPerPage={10} {...configs} />
        <RefinementList title={refinementListTitle} transform={refinementListTransform} />
        {children}
        <Result refreshApiKey={refreshApiKey} />
      </>
    </InstantSearch>
  );
};

export default memo(AlgoliaProvider, ({ apiKey }, { apiKey: newApiKey }) => apiKey === newApiKey);
