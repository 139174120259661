import React from 'react';
import ClearIcon from '@mui/icons-material/Clear';

import { SIconButton, SSearch, STextField } from '../../../../style';
import { TAlgoliaSearchBoxProps } from '../../types';

export function ControlledSearchBox({
  inputRef,
  isSearchStalled,
  onChange,
  onReset,
  placeholder,
  value,
  className,
}: TAlgoliaSearchBoxProps): JSX.Element {
  return (
    <SSearch>
      <STextField
        autoComplete='off'
        autoCorrect='off'
        autoCapitalize='off'
        spellCheck={false}
        fullWidth
        value={value}
        onChange={onChange}
        inputRef={inputRef}
        className={className}
        placeholder={placeholder}
      />
      {(!!value?.length || isSearchStalled) && (
        <SIconButton onClick={onReset}>
          <ClearIcon />
        </SIconButton>
      )}
    </SSearch>
  );
}
