import { AlgoliaRadio } from '../../../components/Radio';
import { TNumericRefinmentListProvided } from '../../../types';
import { NoOptionExist } from '../../../components/NoOptionExist';
import { RefinementListWrapper, SRefinementList, SRefinementListItem } from '../../../styles';

export function WrappedNumericFilter({ items, refine, createURL }: TNumericRefinmentListProvided): JSX.Element {
  if (!items.length) {
    return <NoOptionExist />;
  }

  return (
    <RefinementListWrapper>
      <SRefinementList>
        {items.map((item, index) => (
          <SRefinementListItem key={item.label}>
            <AlgoliaRadio
              item={item}
              noBorder={index === items.length - 1}
              onChange={() => {
                refine(item.value);
              }}
            />
          </SRefinementListItem>
        ))}
      </SRefinementList>
    </RefinementListWrapper>
  );
}
